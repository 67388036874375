import {reactive,toRefs,onBeforeMount,onMounted,defineComponent,getCurrentInstance,nextTick,provide} from 'vue';
import ImageManageUtil,{IImageManageDataObj,IImageSource} from "./imageManageUtil";
let curImag:ResourceFileBean={id: "", saveType: 0, type: 0, uploadTime: "", userId: "", preName:'',nowName: "",path:''}
let imageSource:IImageSource={
    selImageName:'',//当前选择的图片名称
    selImagePath:'', //当前选择的图片地址
    arrayPath: [] //可以选择的图片素材
}
export default defineComponent({
    name: "ImageManage",
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('uploadOwner', proxy);
        let dataObj:IImageManageDataObj=reactive<IImageManageDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            fullscreenLoading:true,
            dialogVisible:false,//查看图片详情弹出框影藏显示控制标志
            curImag:curImag,

            uploadParams:{
                limitNum:9999999,
                uploadType:'imgSources'
            },
            contentAreaHeight:400,//主页图片展示区域的最大高度，超过即出现滚动条
            drawer: false,
            gridData: [],//弹出的drawer中图片表格数据
            tabMaxHeight:0,//弹出的图片url表格最大高度，超过即出现滚动条
            imageSource:imageSource
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ImageManageUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async ()=>{
                dataObj.contentAreaHeight=window.innerHeight-utils.Const.topHeight-utils.Const.tabHeight-65;//65是按钮高度60加上向下margin-bottom: 5px
                //计算表格的最大高度，方便出现滚动条
                dataObj.tabMaxHeight=window.innerHeight-36-20*2-2-40;//-36是标题的高度 -20是card-header的上下padding，-2是上下边框 （谁的就不知道了）-40是表格的标题
                await dataObj.utilInst.getImageData();
                window.onresize = ()=> {
                    if(window.innerHeight){
                        dataObj.tabMaxHeight=window.innerHeight-36-20*2-2-40;
                        dataObj.contentAreaHeight=window.innerHeight-utils.Const.topHeight-utils.Const.tabHeight-65;
                    }
                }
            })
        })

        const deleteHandler=(row:ResourceFileBean)=>{
            //删除表格数据和图片数组中对应项
            let index=dataObj.gridData.findIndex(item=>item.preName==row.preName);

            if(index!=-1){
                //发起后台请求，从磁盘上删除对应记录
                utils.Tools.configBox({
                    message:'即将删除该图片资源文件，是否继续？',
                    sureFn: async()=>{
                        const loading = proxy.$loading({lock: true,text: '正在执行操作，请耐心等候......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
                        let res = await utils.Api.deleteImageSource({id:row.id});
                        if(res.result){
                            dataObj.gridData.splice(index,1);
                            dataObj.imageSource.arrayPath.splice(index,1);
                            utils.Tools.success({message: res.msg});
                            sessionStorage.setItem("imageSource", utils.UtilPub.encrypt(JSON.stringify(dataObj.gridData)));//把图片资源放到sessionStorage中
                        }else{
                            utils.Tools.error({message: '删除失败'});
                        }
                        loading.close();
                    }
                });
            }
        }
        //上传组件完成上传钩子
        const afterResult=(res:any)=>{
            dataObj.imageSource.arrayPath.length=0;//清空主页图片
            res.data.forEach((item:any)=>{
                dataObj.imageSource.arrayPath.push(item.path);
            });
            dataObj.gridData=res.data;//为表格重新赋值
            sessionStorage.setItem("imageSource", utils.UtilPub.encrypt(JSON.stringify(res.data)));//把图片资源放到sessionStorage中
        }

        //查看图片
        const lookDetails=(index:number,row:ResourceFileBean)=>{
            dataObj.dialogVisible=true;
            dataObj.curImag=row;
        }
        return{
            ...toRefs(dataObj),deleteHandler,afterResult,lookDetails
        }
    }
})